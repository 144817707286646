import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { CountryCodeEnum } from "../../app/enums/countryCodeEnum";
import PaymentUnsuccessful from "../../app/components/PaymentUnsuccessful";
import SubscriptionFeesDue from "../../app/models/membership/SubscriptionFeesDue";
import MembershipFormModel from "../../app/models/membership/membershipFormModel";
import { MembershipTypeEnum } from "../../app/enums/MembershipTypeEnum";
import { CurrencyCodeIconEnum } from "../../app/enums/currencyCodeIconEnum";
import { AppPathEnum } from "../../app/enums/appPathEnum";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { PaymentRedirectProps } from "../../app/models/membership/paymentRedirectProps";
import { basketAsync, nextYearEnrolBasketAsync } from "../../app/slices/famEnrolmentSlice";
import { getPaymentIdFromBasketAsync, getPaymentSummaryAsync, getPaymentRedirectAsync } from "../../app/slices/paymentSlice";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";

export default function MembershipRenewalPaymentSummary() {
    const {
        formField: {
            MembershipType,
            MembershipCountry
        }
    } = MembershipFormModel;

    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search);
    const payInAdvance = Boolean(queryParams.get("payInAdvance"));

    const dispatch = useAppDispatch();

    const { countries, famMembershipTypes } = useAppSelector(state => state.ReferenceData);
    const { famDetails } = useAppSelector(state => state.famDetails);
    const { basketId, basketIdStatus, nextYearBasketIdStatus } = useAppSelector(state => state.famEnrolment);
    const { paymentRedirectStatus, paymentDetails, paymentSummaryStatus,
        paymentId, paymentsByPersonStatus, paymentIdStatus } = useAppSelector(state => state.payment);

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if (payInAdvance === true) {
            dispatch(nextYearEnrolBasketAsync());
        }
        else {
            dispatch(basketAsync());
        }
    }, [dispatch, payInAdvance]);

    useEffect(() => {
        if ((basketIdStatus === ApiRequestStatus.Fulfilled || nextYearBasketIdStatus === ApiRequestStatus.Fulfilled)
            && basketId !== null && paymentIdStatus === ApiRequestStatus.Idle) {
            dispatch(getPaymentIdFromBasketAsync(basketId));
        }
    }, [dispatch, basketIdStatus, nextYearBasketIdStatus, paymentIdStatus]);

    useEffect(() => {
        if (paymentId !== null && paymentIdStatus === ApiRequestStatus.Fulfilled
            && paymentDetails === null && paymentSummaryStatus === ApiRequestStatus.Idle) {
            dispatch(getPaymentSummaryAsync(paymentId));
        }
    }, [dispatch, paymentId, paymentIdStatus, paymentDetails, paymentSummaryStatus]);

    const paymentOnClickHandler = () => {
        if (paymentId && paymentId !== null) {
            setPageLoading(true);
            let paymentRedirectProps: PaymentRedirectProps = {
                cancelUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI)}/${window.location.search}&cancelPayment=true`,
                paymentId: paymentId,
                successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI)}`
            };
            dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
                setPageLoading(false);
                window.location.replace(paymentRedirectResponse.payload);
            });
        }
    }

    const payByDirectDebit = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const cancelUrlFromUrl = queryParams.get("cancelUrl");
        const basketIdFromUrl = queryParams.get("basketId");
        let urlToRedirect = '';

        if ((!cancelUrlFromUrl || cancelUrlFromUrl === null) && basketIdFromUrl === null) {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&cancelUrl=${AppPathEnum.MEMBERSHIP_RENEWAL}&basketId=${basketId}`;
        }
        else if (!cancelUrlFromUrl || cancelUrlFromUrl === null) {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&cancelUrl=${AppPathEnum.MEMBERSHIP_RENEWAL}`;
        }
        else if (basketIdFromUrl === null) {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}&basketId=${basketId}`;
        }
        else {
            urlToRedirect = `${AppPathEnum.MEMBERSHIP_DIRECT_DEBIT}${window.location.search}`;
        }

        history.push(urlToRedirect);
    }

    if (pageLoading === true
        || basketIdStatus === ApiRequestStatus.Pending
        || paymentsByPersonStatus === ApiRequestStatus.Pending
        || paymentRedirectStatus === ApiRequestStatus.Pending
        || nextYearBasketIdStatus === ApiRequestStatus.Pending
        || paymentSummaryStatus === ApiRequestStatus.Pending
        || paymentIdStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ flexGrow: 1 }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                {((new URLSearchParams(window.location.search)).get("cancelPayment") === undefined || (new URLSearchParams(window.location.search)).get("cancelPayment") !== 'true') &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Payment summary</Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={6} md={4} sm={3} sx={{
                            color: 'black', textAlign: 'start',
                            marginBottom: '3px', paddingTop: '1.5% !important', paddingBottom: '1.5%'
                        }}>
                            <b>{MembershipType.label}</b>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1}></Grid>

                        <Grid item xs={5} md={7} sm={8} sx={{ paddingTop: '1.5% !important', paddingBottom: '1.5%' }}>
                            {famDetails?.membershipTypeId === null ? '' : famMembershipTypes?.find(x => x.id ===
                                famDetails?.membershipTypeId)?.value}
                        </Grid>

                        <Grid item xs={6} md={4} sm={3} sx={{
                            color: 'black', textAlign: 'start',
                            marginBottom: '3px', paddingTop: '1.5% !important', paddingBottom: '1.5%'
                        }}>
                            <b>{MembershipCountry.label}</b>
                        </Grid>

                        <Grid item xs={1} md={1} sm={1}></Grid>

                        <Grid item xs={5} md={7} sm={8} sx={{ paddingTop: '1.5% !important', paddingBottom: '1.5%' }}>
                            {famDetails?.countryOfPracticeId === null ? '' : countries?.find(x => x.id ===
                                famDetails?.countryOfPracticeId)?.value}
                        </Grid>

                        <Grid item xs={11.5} sm={11.5} sx={{ marginTop: "2%", backgroundColor: "#b9b9b9", padding: "1%" }}>
                            <SubscriptionFeesDue paymentDetails={paymentDetails} />
                        </Grid>

                        <Grid item xs={11.5} sx={{ fontStyle: 'italic' }}>
                            {(famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.code === MembershipTypeEnum.FELLOW ||
                                famMembershipTypes?.find(x => x.id === famDetails?.membershipTypeId)?.code === MembershipTypeEnum.MEMBER) &&
                                countries?.find(x => x.id === famDetails?.countryOfPracticeId)?.alpha3Code === CountryCodeEnum.IRELAND &&
                                <>
                                    <Typography variant='subtitle1'>Professional Competence Scheme (PCS) Surgery</Typography>
                                    <br></br>
                                    <Typography variant='subtitle1'>If you are a Member or Fellow and practice in the Republic of Ireland, you are entitled
                                        to a {CurrencyCodeIconEnum.EUR}150 discount on your Professional Competence Scheme (PCS) fee if you pay
                                        before 1 July of the current PCS year. *The PCS & Membership Year runs from
                                        1st May - 30th April each year.
                                    </Typography>
                                </>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Please note that by clicking on the below you will be brought to a
                                third party system for payment processing.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={paymentOnClickHandler}>
                                Pay by card
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={payByDirectDebit}>
                                Pay by direct debit
                            </Button>
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </>
                }

                {(new URLSearchParams(window.location.search)).get("cancelPayment") === 'true' && process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI &&
                    <PaymentUnsuccessful retryUrl={process.env.REACT_APP_MEMBERSHIP_RENEWAL_URI} />}

            </Grid>
        </Box >
    </>
}