import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { downloadFileAsync } from "./documentSlice";
import { FileDto } from "../../app/models/membership/fileDto";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Paper, Button, Box, Grid, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import React from "react";
import { formatBytes, formatDateWithTime } from "../../app/utils/util";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../app/enums/userClaims";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { useReferenceData } from "../../app/customHooks/useReferenceData";
import { useGetFiles } from "../../app/customHooks/useGetFiles";

export function MyDocuments() {

    const dispatch = useAppDispatch();

    const { files, downloadStatus } = useAppSelector(state => state.document);
    const { documentTypes } = useAppSelector(state => state.ReferenceData);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pageLoading, setPageLoading] = useState(false);

    const { staticDataStateStatus } = useReferenceData();
    const { status } = useGetFiles();

    const downloadFile = (name: string | null) => {
        if (name !== null) {
            setPageLoading(true);
            dispatch(downloadFileAsync({ personId: personId, fileName: name })).then((response: any) => {
                const url = window.URL.createObjectURL(new Blob([response.payload], { type: 'application/octet-stream' }));

                const link = document.createElement('a');
                link.href = url;
                link.download = response.meta.arg.fileName;

                document.body.appendChild(link);

                link.click();
                link.remove();

                window.URL.revokeObjectURL(url);
                setPageLoading(false);
            });
        }
    }

    if (pageLoading === true
        || staticDataStateStatus === ApiRequestStatus.Pending
        || status === ApiRequestStatus.Pending
        || downloadStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }} component={Paper}>
                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Typography variant='h3' textAlign={"center"}>My documents</Typography>
                    </Grid>

                    <Grid item xs={12}></Grid>

                </Grid>

                <Grid container component={Paper} sx={{
                    backgroundColor: '#616161',
                    color: "#fff",
                    pt: 2,
                    pb: 2
                }}>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={2}>
                        File name
                    </Grid>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={2.5}>
                        Document type
                    </Grid>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={1.5}>
                        File size
                    </Grid>

                    <Grid item xs={0.5}></Grid>

                    <Grid item xs={2}>
                        Modified date
                    </Grid>

                    <Grid item xs={2}></Grid>

                </Grid>

                {(files === undefined || files == null || files?.length === 0) &&
                    <Grid container component={Paper} sx={{
                        textAlign: 'left',
                        alignItems: 'center'
                    }}>

                        <Grid item xs={12}></Grid>
                        <br />

                        <Grid item xs={12} sx={{
                            textAlign: 'center'
                        }}>
                            No documents found.
                        </Grid>

                        <Grid item xs={12}></Grid>
                        <br />

                    </Grid>
                }

                {files?.map((item: FileDto, index: number) => (
                    <React.Fragment key={index}>
                        <Grid container component={Paper} sx={{
                            alignItems: 'center'
                        }}>
                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={2}>
                                {`${item.name}`}
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={2.5}>
                                {item.tags !== null &&
                                    documentTypes?.find(x => x.code === item.tags?.FILE_TYPE)?.publicDisplayName}
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={1.5}>
                                {item.size && item.size !== null && formatBytes(item.size)}
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={2}>
                                {item.modifiedDate && item.modifiedDate !== null &&
                                    formatDateWithTime(new Date(item.modifiedDate))}
                            </Grid>

                            <Grid item xs={2} sx={{ padding: 1 }}>
                                <Button variant="outlined" sx={{
                                    '& .MuiButton-startIcon': {
                                        marginRight: '0',
                                        marginLeft: '0'
                                    },
                                    minWidth: '0'
                                }} startIcon={<DownloadIcon />}
                                    onClick={() => downloadFile(item.name)} >
                                </Button>
                            </Grid>

                        </Grid>
                    </React.Fragment>
                ))}

            </Box>
        </>
    );
}
