import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import { history } from "../..";
import { CpdDto } from "../../features/pcsViewCpdRecords/models/cpdDto";
import { SearchCreditRecordsQuery } from "../../features/pcsViewCpdRecords/models/searchCreditRecordsQuery";
import AppToasterWithErrorMessages from "../components/AppToasterWithErrorMessages";
import { PlannedActivity } from "../../features/pcsPlan/models/plannedActivity";
import { ReviewerDetails } from "../../features/pcsPlan/models/reviewerDetails";
import { CancelPlanParameters } from "../../features/pcsPlan/models/cancelPlanParameters";
import { ConfirmReviewerDetailsParameters } from "../../features/pcsPlan/models/confirmReviewerDetailsParameters";
import { RevieweeCommentDto } from "../../features/pcsPlan/models/revieweeCommentDto";
import { PersonalDetailsDto } from "../models/person/personalDetailsDto";
import { FAMDetails } from "../models/membership/famDetails";
import { AlumnusDetails } from "../../features/updateProfile/models/alumniDetails";
import { FAMOnboarding } from "../models/membership/famOnboarding";
import { EducationalDetailsDto } from "../models/person/educationalDetailsDto";
import { VolunteeringInterestDto } from "../models/volunteering/common/volunteeringInterestDto";
import { PaymentRedirectProps } from "../models/membership/paymentRedirectProps";
import { CreateDonationBasketCommand } from "../models/donation/createDonationBasketCommand";
import { CreateRequestTaskCommand } from "../models/request/createRequestTaskCommand";
import { toast } from "react-toastify";
import UpdateAffiliateOnboardingDto from "../models/affiliateOnboarding/updateAffiliateOnboardingDto";
import { RegistrationDetailsDto } from "../models/pcsEnrolment/registrationDetailsDto";
import { WorkDetailsDto } from "../models/pcsEnrolment/workDetails/workDetailsDto";
import { CreateDirectDebitMandateCommand } from "../models/directDebit/createDirectDebitMandateCommand";
import { CreatePaymentIntentFromBasketCommand } from "../models/directDebit/createPaymentIntentFromBasketCommand";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.response.use(async response => {
    return response;
}, (error: AxiosError) => {
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key])
                    }
                }
                if (modelStateErrors.length > 1) {
                    AppToasterWithErrorMessages(modelStateErrors);
                }
                else if (modelStateErrors.length === 1) {
                    toast.error(modelStateErrors[0][0]);
                }
                throw modelStateErrors.flat();
            }
            break;
        case 401:
            history.push({ pathname: '/unauthorized' });
            break;
        case 404:
        case 500:
            history.push({ pathname: '/server-error' });
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
})

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    getConfig: (url: string, config: {}) => axios.get(url, config).then(responseBody),
    post: (url: string, body: {}, config: {} = {}) => axios.post(url, body, config).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
}

const ReferenceData = {
    getReferenceData: () => requests.get(`ReferenceData`)
}

const PcsRegistration = {
    getRegistrationDetails: (personId: string) => requests.get(`PCS/${personId}`),
    getPersonalDetails: (personId: string) => requests.get(`Person/${personId}`),
    getEducationalDetails: (personId: string) => requests.get(`PCS/${personId}/EducationalDetails`),
    getWorkDetails: (personId: string) => requests.get(`PCS/${personId}/WorkDetails`),
    submitRegistrationDetails: (personId: string, data: RegistrationDetailsDto) => requests.put(`PCS/${personId}/RegistrationDetails`, data),
    submitPersonalDetails: (personId: string, data: PersonalDetailsDto) => requests.put(`PCS/${personId}/PersonalDetails`, data),
    submitEducationalDetails: (personId: string, data: EducationalDetailsDto) => requests.put(`PCS/${personId}/EducationalDetails`, data),
    submitWorkDetails: (personId: string, data: WorkDetailsDto) => requests.put(`PCS/${personId}/WorkDetails`, data),
}

const PersonalDetails = {
    getPersonalDetails: (personId: string) => requests.get(`Person/${personId}`),
    submitPersonalDetails: (data: PersonalDetailsDto) => requests.post(`Person`, data),
    updatePersonalDetails: (data: PersonalDetailsDto) => requests.put(`Person/${data.id}`, data)
}

const EducationalDetails = {
    getEducationalDetails: () => requests.get(`Pmd`),
    saveEducationalDetails: (data: EducationalDetailsDto) => requests.post(`Pmd`, data),
    updateEducationalDetails: (data: EducationalDetailsDto) => requests.put(`Pmd`, data)
}

const SixYearSummary = {
    summaries: (personId: string) => requests.get(`PCS/${personId}/Summary`)
}

const FellowAndMember = {
    getFAMDetails: (personId: string) => requests.get(`Fam/${personId}`)
}

const AffiliateOnboarding = {
    getAffiliateOnboarding: () => requests.get(`AffiliateOnboarding`),
    getAffiliateOnboardingCanPayStatusCodesAsync: () => requests.get(`AffiliateOnboarding/CanPayStatusCodes`),
    updateAffiliateOnboarding: (data: UpdateAffiliateOnboardingDto) => requests.put(`AffiliateOnboarding`, data),
    basketAsync: () => requests.post(`AffiliateOnboarding/Basket`, {})
}

const MemberTransfer = {
    getTransferInformation: () => requests.get(`FamTransfer`),
    getFamTransferCanPayStatusCodesAsync: () => requests.get(`FamTransfer/CanPayStatusCodes`),
    saveTransferInformation: (data: FAMOnboarding) => requests.post(`FamTransfer`, data),
    updateTransferInformation: (data: FAMOnboarding) => requests.put(`FamTransfer`, data),
    saveDocument: (data: FormData) => requests.post(`FamTransfer/Document`, data, {
        headers: {
            "content-type": "multipart/form-data"
        },
    }),
    submitTransferDetails: (famExamId: string) => requests.put(`FamTransfer/Submit`, { FamExamId: famExamId }),
    getFiles: () => requests.get(`FamTransfer/Documents`),
    downloadFile: (name: string) => requests.getConfig(`FamTransfer/Document/${name}`, {
        responseType: 'blob',
    }),
    deleteFile: (fileType: string) => requests.delete(`FamTransfer/Document?FileType=${fileType}`),
    basketAsync: () => requests.post(`FamTransfer/Basket`, {})
}

const MemberOnboarding = {
    getFamOnboardingDetailsAsync: () => requests.get(`FamOnboarding`),
    getFamOnboardingCanPayStatusCodesAsync: () => requests.get(`FamOnboarding/CanPayStatusCodes`),
    getByIdAsync: (id: string) => requests.get(`FamOnboarding/${id}`),
    updateFamOnboardingDetailsAsync: (data: FAMOnboarding) => requests.put(`FamOnboarding`, data),
    submitFamOnboardingDetailsAsync: (famExamId: string) => requests.put(`FamOnboarding/Submit`, { FamExamId: famExamId }),
    basketAsync: (famExamId: string) => requests.post(`FamOnboarding/Basket`, { FamExamId: famExamId })
}

const Cpd = {
    addCpdDetails: (data: CpdDto) => requests.post(`Credits`, data),
    updateCpdDetails: (data: CpdDto) => requests.put(`Credits`, data),
    cloneCpdDetails: (data: CpdDto) => requests.post(`Credits`, data),
    deleteCpdDetails: (id: string) => requests.delete(`Credits/${id}`),
    getCpdDetails: (id: string) => requests.get(`Credits/${id}`),
    getCpdStatusHistory: (id: string) => requests.get(`Credits/${id}/StatusHistory`),
    getCreditRecords: (data: SearchCreditRecordsQuery) =>
        axios.get(`Credits`, {
            params: {
                personId: data.personId,
                year: data.year,
                cpdCategoryID: data.cpdCategoryID,
                cpdRecordStatusID: data.cpdRecordStatusID
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(responseBody)
}

const PcsPlan = {
    getWorkPost: () => requests.get(`Workpost`),
    getPlanDetails: (personId: string) => requests.get(`PcsEnrolment/${personId}`),
    getReviewerDetails: (personId: string) => requests.get(`PcdPdp?ReviewerPersonId=${personId}`),
    getPcsEnrolmentYears: (personId: string) => requests.get(`PcsEnrolment/${personId}`),
    updatePlannedActivity: (data: PlannedActivity) => requests.put(`PcdPdp`, data),
    submitReviewerDetails: (data: ReviewerDetails) => requests.post(`PcdPdp/Reviewer`, data),
    saveRevieweeComment: (data: RevieweeCommentDto) => requests.put(`PcdPdp/Comment`, data),
    confirmReviewerDetails: (data: ConfirmReviewerDetailsParameters) => requests.put(`PcdPdp/Reviewer`, data),
    cancelPlan: (data: CancelPlanParameters) => requests.put(`PcdPdp/Cancel`, data),
    getCurrentYear: () => requests.get(`PCS/CurrentYear`),
    searchReviewerByImcNumber: (imcNumber: string) => requests.get(`PcdPdp/Reviewer?IMCNumber=${imcNumber}`),
    searchReviewerByPersonId: (personId: string) => requests.get(`PcdPdp/Reviewer?PersonId=${personId}`),
    getActivitiesAcrossDomains: () => requests.get(`PcdPdp/DomainSummary`)
}

const UpdateProfile = {
    getAlumniDetails: (personId: string) => requests.get(`Alumni/${personId}`),
    updateAlumniDetails: (data: AlumnusDetails) => requests.put(`Alumni`, data)
}

const Fam = {
    getFAMDetails: (personId: string) => requests.get(`Fam/${personId}`),
    getFamSubscriptionsAsync: () => requests.get('Fam/Subscriptions'),
    getCurrentYear: () => requests.get(`Fam/CurrentYear`),
    getCurrentMembershipTypeAsync: () => requests.get(`Fam/CurrentMembershipType`),
    updateFamDetails: (data: FAMDetails) => requests.put(`Fam`, data),
    addFamDetails: (data: FAMDetails) => requests.post(`Fam`, data),
    createReEnrolBasketAsync: () => requests.post(`Fam/CreateReEnrolBasket`, {}),
    getFamExamDetailsAsync: () => requests.get(`Fam/Exams`),
    getCanUseDirectDebitMandateAsync: () => requests.get(`Fam/CanUseDirectDebitMandate`)
}

const Documents = {
    getFiles: () => requests.get(`Person/Documents`),
    getFile: (id: string, name: string) => requests.getConfig(`Person/Document/${id}/${name}`, {
        responseType: 'blob',
    })
}

const VolunteeringInterests = {
    getVolunteeringInterestsAsync: () => requests.get(`VolunteeringInterests`),
    updateVolunteeringInterestsAsync: (volunteeringInterests: VolunteeringInterestDto[]) =>
        requests.put(`VolunteeringInterests`, { volunteeringInterests: volunteeringInterests })
}

const Payment = {
    getPaymentRedirectAsync: (paymentRedirectProps: PaymentRedirectProps) =>
        requests.get(`Payment/Redirect?SuccessUrl=${paymentRedirectProps.successUrl}&CancelUrl=${paymentRedirectProps.cancelUrl}&PaymentId=${paymentRedirectProps.paymentId}`),
    getPaymentByPaymentIdAsync: (id: string) => requests.get(`Payment/${id}`),
    getPaymentsAsync: () => requests.get(`Payment`),
    getPaymentIdFromBasketAsync: (shoppingBasketId: string) => requests.post(`Payment/FromBasket`, { ShoppingBasketId: shoppingBasketId }),
    getSelfServiceAsync: () => requests.get(`Payment/SelfService`)
}

const FamEnrolment = {
    getEnrolmentYearsAsync: () => requests.get(`FamEnrolment`),
    basketAsync: () => requests.post(`FamEnrolment/ReEnrolBasket`, {}),
    nextYearEnrolBasketAsync: () => requests.post(`FamEnrolment/NextYearEnrolBasket`, {})
}

const Donation = {
    getDonationDetailsAsync: () => requests.get(`Donations/Commitments`),
    submitToBasketAsync: (donationCommand: CreateDonationBasketCommand) => requests.post(`Donations/Basket`, donationCommand)
}

const DirectDebit = {
    submitDirectDebitDetailsAsync: (createDirectDebitMandateCommand: CreateDirectDebitMandateCommand) =>
        requests.post(`DirectDebitMandate/FromBasket`, createDirectDebitMandateCommand),
    getDirectDebitMandateByIdAsync: (id: string) => requests.get(`DirectDebitMandate/${id}`),
    getDirectDebitMandatesAsync: () => requests.get(`DirectDebitMandate`),
    deleteDirectDebitMandateByIdAsync: (id: string) => requests.delete(`DirectDebitMandate/${id}`),
    payByDDMandateFromBasketAsync: (createPaymentIntentFromBasketCommand: CreatePaymentIntentFromBasketCommand) =>
        requests.post(`DirectDebitMandate/PayByDDMandateFromBasket`, createPaymentIntentFromBasketCommand)
}

const Requests = {
    submitRequestAsync: (requestCommand: CreateRequestTaskCommand) => requests.post(`Request`, requestCommand)
}

const CardSubscription = {
    getCardSubscriptionAsync: () => requests.get(`CardSubscription`)
}

const agent = {
    ReferenceData,
    PcsRegistration,
    PersonalDetails,
    EducationalDetails,
    PcsPlan,
    SixYearSummary,
    FellowAndMember,
    AffiliateOnboarding,
    MemberTransfer,
    MemberOnboarding,
    UpdateProfile,
    Fam,
    Cpd,
    Documents,
    VolunteeringInterests,
    Payment,
    FamEnrolment,
    Donation,
    Requests,
    DirectDebit,
    CardSubscription
}

export default agent;