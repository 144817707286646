import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfDocument } from './PdfDocument';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { useAppSelector } from '../../app/store/configureStore';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { MESSAGE_PROCESSING_DETAILS } from '../../app/utils/constant';
import { useGetCurrentYear } from '../../app/customHooks/useGetCurrentYear';
import { useGetFamDetails } from '../../app/customHooks/useGetFamDetails';
import { useGetFamExams } from '../../app/customHooks/useGetFamExams';
import { useReferenceData } from '../../app/customHooks/useReferenceData';

export default function InGoodStanding() {
  const { staticDataStateStatus } = useReferenceData();
  const { currentYearStatus } = useGetCurrentYear();
  const { famDetailsStatus } = useGetFamDetails();
  const { famOnboardingDetailsStatus } = useGetFamExams();

  const { famMembershipTypes } = useAppSelector(state => state.ReferenceData);
  const { currentYear } = useAppSelector(state => state.plan);
  const { famOnboardingDetails, famDetails } = useAppSelector(state => state.famDetails);

  if (staticDataStateStatus === ApiRequestStatus.Pending || currentYearStatus === ApiRequestStatus.Pending
    || famDetailsStatus === ApiRequestStatus.Pending || famOnboardingDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return <>
    Our Records show that you are <b>In Good Standing</b> for the current membership year.
    You can generate a Letter of Good Standing {" "}
    <PDFDownloadLink document={<PdfDocument famMembershipTypes={famMembershipTypes}
      famOnboardingDetails={famOnboardingDetails && famOnboardingDetails[0]} currentYear={currentYear} famDetails={famDetails} />}
      fileName="RCSI_Letter_of_Good_Standing_Year.pdf">
      {({ loading }) =>
        loading === true ? 'Loading document...' : 'here'}
    </PDFDownloadLink>{". "}
    A Letter of Good Standing can be used to confirm your Good Standing status with RCSI for
    the current subscription year, your entitlement to use the FRCSI or MRCSI post-nominals
    and to verify your award.
  </>
}