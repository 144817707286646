import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { CheckBoxItem } from '../../app/models/common/checkBoxItem';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { postInIrelandDropDownValues } from '../../app/utils/util';
import registrationFormModel from './common/registrationFormModel';
import AppDatePicker from '../../app/components/AppDatePicker';
import { useFormContext } from 'react-hook-form';
import AppCheckboxField from '../../app/components/AppCheckboxField';
import AppSelectList from '../../app/components/AppSelectList';
import AppTextInput from '../../app/components/AppTextInput';
import { DropdownItem } from '../../app/models/common/dropdownItem';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import { getWorkDetailsState } from '../../app/slices/workDetailsSlice';

export default function WorkDetails(props: any) {

  const methods = useFormContext();
  const { control } = methods;

  const dispatch = useAppDispatch();
  const { workDetails, workDetailsStateStatus, saveWorkDetailsStatus } = useAppSelector(state => state.workDetail);

  const { workDetailsFormField } = registrationFormModel;

  const [nameOfEmployers] = useState<DropdownItem[] | null>([]);

  const [postLevelIfEngagedInPracticeOfMedicine, setPostLevelIfEngagedInPracticeOfMedicine] = useState<CheckBoxItem[]>([]);
  const [ifEngagedInPracticeOfMedicine, setIfEngagedInPracticeOfMedicine] = useState<boolean>(false);
  const [postLevelIfNotEngagedInPracticeOfMedicine, setPostLevelIfNotEngagedInPracticeOfMedicine] = useState<CheckBoxItem[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [isPostInIreland, setIsPostInIreland] = useState('');

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  useEffect(() => {
    setPostLevelIfEngagedInPracticeOfMedicine([
      { id: 'Consultant', value: 'Consultant', checked: false },
      { id: 'Fellow', value: 'Fellow', checked: false },
      { id: 'SeniorHouseOfficer', value: 'Senior House Officer', checked: true },
      { id: 'ResidentMedicalOfficer', value: 'Resident Medical Officer', checked: false },
      { id: 'Other', value: 'Other', checked: false }]);

    setPostLevelIfNotEngagedInPracticeOfMedicine([
      { id: 'Academic', value: 'Academic', checked: false },
      { id: 'Research', value: 'Research', checked: false },
      { id: 'Retired', value: 'Retired', checked: false },
      { id: 'Other', value: 'Other', checked: false }]);
  }, []);

  useEffect(() => {
    setIfEngagedInPracticeOfMedicine(postLevelIfEngagedInPracticeOfMedicine.filter(x => x.checked === true).length > 0);
  }, [postLevelIfEngagedInPracticeOfMedicine]);

  useEffect(() => {
    if (!workDetails) {
      dispatch(getWorkDetailsState(personId));
    }
  }, [workDetails, dispatch]);

  useEffect(() => {
    if (workDetails) {
      setSelectedStartDate(workDetails?.startDate!);
      setIsPostInIreland(workDetails?.isPostInIreland!);
    }
  }, [workDetails]);

  if (workDetailsStateStatus === ApiRequestStatus.Pending
    || saveWorkDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message='Saving work details...' />
  }

  return (
    <>
      <Typography variant='h3'>Work details</Typography>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={4}>

          <Grid item xs={12}>
            <AppDatePicker
              control={control}
              name={workDetailsFormField.StartDate.name}
              label={workDetailsFormField.StartDate.label} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle1'>{workDetailsFormField.IfEngagedInPracticeOfMedicine.label}</Typography>
          </Grid>

          <Grid item xs={12}>
            <AppCheckboxField
              control={control}
              name={workDetailsFormField.IfEngagedInPracticeOfMedicine.name}
              label={workDetailsFormField.IfEngagedInPracticeOfMedicine.label}
              options={postLevelIfEngagedInPracticeOfMedicine} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle1'>{workDetailsFormField.IfNotEngagedInPracticeOfMedicine.label}</Typography>
          </Grid>

          <Grid item xs={12}>
            <AppCheckboxField
              control={control}
              name={workDetailsFormField.IfNotEngagedInPracticeOfMedicine.name}
              label={workDetailsFormField.IfNotEngagedInPracticeOfMedicine.label}
              options={postLevelIfNotEngagedInPracticeOfMedicine} />
          </Grid>

          {ifEngagedInPracticeOfMedicine &&
            <>
              <Grid item xs={12}>
                <AppSelectList control={control}
                  name={workDetailsFormField.IsPostInIreland.name}
                  label={workDetailsFormField.IsPostInIreland.label}
                  items={postInIrelandDropDownValues()} />
              </Grid>

              {isPostInIreland.toLowerCase() === 'yes' &&
                <>
                  <Grid item xs={12}>
                    <AppSelectList control={control}
                      name={workDetailsFormField.NameOfEmployer.name}
                      label={workDetailsFormField.NameOfEmployer.label}
                      items={nameOfEmployers ?? []} />
                  </Grid>

                  <Grid item xs={12}>
                    <AppTextInput control={control}
                      name={workDetailsFormField.NameOfClinicalSupervisor.name}
                      label={workDetailsFormField.NameOfClinicalSupervisor.label} />
                  </Grid>
                </>
              }
            </>
          }
        </Grid>
      </Box >
    </>
  );
}
