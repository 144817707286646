import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { EducationalDetailsDto } from "../models/person/educationalDetailsDto";

interface EducationalDetailsState {
    educationalDetails: EducationalDetailsDto | null;

    educationalDetailsStatus: ApiRequestStatus;
    saveEducationalDetailsStatus: ApiRequestStatus;
    updateEducationalDetailsStatus: ApiRequestStatus;
}

const initialState: EducationalDetailsState = {
    educationalDetails: null,

    educationalDetailsStatus: ApiRequestStatus.Idle,
    saveEducationalDetailsStatus: ApiRequestStatus.Idle,
    updateEducationalDetailsStatus: ApiRequestStatus.Idle
}

export const getEducationalDetailsAsync = createAsyncThunk<EducationalDetailsDto>(
    'educationalDetails/getEducationalDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.EducationalDetails.getEducationalDetails();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveEducationalDetailsAsync = createAsyncThunk<void, EducationalDetailsDto>(
    'educationalDetails/saveEducationalDetailsAsync',
    async (educationalDetails, thunkAPI) => {
        try {
            return agent.EducationalDetails.saveEducationalDetails(educationalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateEducationalDetailsAsync = createAsyncThunk<void, EducationalDetailsDto>(
    'educationalDetails/updateEducationalDetailsAsync',
    async (educationalDetails, thunkAPI) => {
        try {
            return agent.EducationalDetails.updateEducationalDetails(educationalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

const educationalDetailsSlice = createSlice({
    name: 'educationalDetailsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getEducationalDetailsAsync.pending, (state) => {
            state.educationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getEducationalDetailsAsync.fulfilled, (state, action) => {
            state.educationalDetailsStatus = ApiRequestStatus.Fulfilled;
            state.educationalDetails = action.payload;
        });
        builder.addCase(getEducationalDetailsAsync.rejected, (state) => {
            state.educationalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveEducationalDetailsAsync.pending, (state) => {
            state.saveEducationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveEducationalDetailsAsync.fulfilled, (state, action) => {
            state.educationalDetails = action.meta.arg;
            state.saveEducationalDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveEducationalDetailsAsync.rejected, (state) => {
            state.saveEducationalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateEducationalDetailsAsync.pending, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateEducationalDetailsAsync.fulfilled, (state, action) => {
            state.educationalDetails = action.meta.arg;
            state.updateEducationalDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateEducationalDetailsAsync.rejected, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})

export default educationalDetailsSlice;