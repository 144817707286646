import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MyRecurringPayments } from "./MyRecurringPayments";
import MyReceipts from "./MyReceipts";
import { MyDirectDebits } from "./MyDirectDebits";
import { MyReceiptsStepEnum } from "../common/myReceiptsStepEnum";
import { useGetPersonalDetails } from "../../../app/customHooks/useGetPersonalDetails";
import { useGetDonationDetails } from "../../../app/customHooks/useGetDonationDetails";
import { useGetFamSubscriptions } from "../../../app/customHooks/useGetFamSubscriptions";
import { useGetPayments } from "../../../app/customHooks/useGetPayments";
import { useGetSelfService } from "../../../app/customHooks/useGetSelfService";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";

export function MyReceiptsForm() {
    const queryParams = new URLSearchParams(window.location.search);
    const initialStep = queryParams.get("initialStep");

    const tabs = [MyReceiptsStepEnum.MyReceipts, MyReceiptsStepEnum.MyRecurringCardPayments, MyReceiptsStepEnum.MyDirectDebits];

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <MyReceipts />
            case 1:
                return <MyRecurringPayments />
            case 2:
                return <MyDirectDebits />
            default:
                throw new Error('Unknown step');
        }
    }

    const [activeStep, setActiveStep] = useState((initialStep === undefined || initialStep === null) ? 0 : parseInt(initialStep));

    const methods = useForm({
        mode: 'all'
    });

    const { staticDataStateStatus } = useReferenceData();
    const { personalDetailsStatus } = useGetPersonalDetails();
    const { donationDetailsStatus } = useGetDonationDetails();
    const { paymentsByPersonStatus } = useGetPayments();
    const { famSubscriptionsStatus } = useGetFamSubscriptions();
    const { selfServiceStatus } = useGetSelfService();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveStep(newValue);
    };

    if (staticDataStateStatus === ApiRequestStatus.Pending ||
        personalDetailsStatus === ApiRequestStatus.Pending ||
        donationDetailsStatus === ApiRequestStatus.Pending ||
        paymentsByPersonStatus === ApiRequestStatus.Pending ||
        famSubscriptionsStatus === ApiRequestStatus.Pending ||
        selfServiceStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4">
                    My Receipts and Recurring payments
                </Typography>

                <br></br>

                <Tabs value={activeStep}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }}>
                    {tabs.map((label: string, inedex: number) => (
                        <Tab key={label} value={inedex} label={label} />
                    ))}
                </Tabs>

                {getStepContent(activeStep)}
            </Paper>
        </FormProvider >
    </>
}
