export enum AppPathEnum {
    ALUMNI_VOLUNTEERING = "/alumnivolunteering",
    DONATION_DASHBOARD = "/donation-dashboard",
    DONATION_REGION = "/donation/:region",
    DONATION_IRELAND = `/donation/IRELAND`,
    DONATION_USA = `/donation/USA`,
    DONATION_UK = `/donation/UK`,
    PCS_EVENT_APPROVAL = "/pcseventapproval",
    PCS_PLAN_REVIEW = "/pcsplanreview",
    PCS_VIEW_CPD_RECORDS = "/pcsviewcpdrecords",
    PCS_VIEW_CPD_RECORDS_CREDIT_RECORD_ID = '/pcsviewcpdrecord/:creditRecordId',
    PCS_ADD_CPD_RECORD = "/pcsaddcpdrecord",
    PCS_UPDATE_CPD_RECORD_CREDIT_RECORD_ID = "/pcsupdatecpdrecord/:creditRecordId",
    PCS_CLONE_CPD_RECORD_CREDIT_RECORD_ID = "/pcsclonecpdrecord/:creditRecordId",
    PCS_RE_ENROLL = "/pcsre-enroll",
    PCS_SUMMARY = "/pcssummary",
    PCS_PLAN = "/pcsplan",
    PCS_VIEW_PLANNED_ACTIVITIES = "/pcsViewPlannedActivities",
    PCS_REVIEWER_DETAILS_ENROLMENT_YEAR_ID = "/pcsReviewerDetails/:enrolmentYearId",
    PCS_PLAN_DETAILS_ENROLMENT_YEAR_ID = "/pcsplandetails/:enrolmentYearId",
    PCS_PLAN_FEEDBACK_ENROLMENT_YEAR_ID = "/pcsplanfeedback/:enrolmentYearId",
    PCS_REGISTRATION = "/pcsregistration",
    MEMBERSHIP_ONBOARDING = "/membershiponboarding",
    MEMBERSHIP_TRANSFER = "/membershiptransfer",
    AFFILIATE_ASSOCIATE = "/affiliateassociate",
    MEMBERSHIP_VOLUNTEERING = "/membershipvolunteering",
    MEMBERSHIP_RENEWAL = "/membershiprenewal",
    UPDATE_PROFILE = "/updateprofile",
    MY_RECEIPTS = "/myreceipts",
    MY_DOCUMENTS = "/mydocuments",
    ACCESS_REQUEST = "/accessrequest",
    REQUEST = "/request",
    DONATIONS_DIRECT_DEBIT = "/donation-direct-debit",
    MEMBERSHIP_DIRECT_DEBIT = "/membership-direct-debit",
    MANAGE_DIRECT_DEBIT = "/manage-direct-debit/:directDebitId",
    MEMBER_IN_GOOD_STANDING = "/member-in-good-standing",
    PAYMENT_SUCCESS_URI = "/payment-success",
    DONATION_PAYMENT_SUCCESS_URI = "/donation-payment-success",
    RequestPortalAccess = "/request-portal-access",
    Reductions_And_Exemptions = "/reductions-and-exemptions",
    MERCHANDISE = "/merchandise",
    LINKEDIN = "/linkedIn",
    PASTEST = "/pastest",
    CLASS_NOTES = "/class-notes",
    TOURS = "/tours",
    CONTACT_CLASSMATES = "/contact-classmate",
    YEAR_BOOK = "/year-book",
    ACCESS_CARD_ALUMNI = "/access-card-alumni",
    ACCESS_CARD_FELLOW_AND_MEMBER = "/access-card-fellow-and-member",
    ABSENCE_FROM_PRACTICE_PCS = "/absence-from-practice-pcs",
    REQUEST_TO_WITHDRAW_PCS = "/request-to-withdraw-pcs",
    VOLUNTEER_REQUEST = "/volunteer-request",
    CHANGE_OF_NAME = "/change-name"
}