import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personalDetailsSlice';
import {
  API_REQUEST_FULLFILLED, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NEXT, MESSAGE_PROCESSING_DETAILS,
  MESSAGE_SAVE_AND_CONTINUE, MESSAGE_DETAILS_SAVED,
  MESSAGE_BACK_TO_DONATIONS
} from '../../app/utils/constant';
import { toast } from 'react-toastify';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import MembershipRenewalHeader from './Header';
import { DonationsIrelandStepEnum } from './common/donationsIrelandStepEnum';
import { DonationValidationSchemas } from './common/donationValidationSchemas';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import PersonalDetails from './PersonalDetails';
import PostalAddress from './PostalAddress';
import DonationDetails from './DonationDetails';
import { submitToBasketAsync } from '../../app/slices/donationSlice';
import { CreateDonationBasketCommand } from '../../app/models/donation/createDonationBasketCommand';
import { getPaymentIdFromBasketAsync, getPaymentRedirectAsync } from '../../app/slices/paymentSlice';
import { PaymentRedirectProps } from '../../app/models/membership/paymentRedirectProps';
import { useHistory, useParams } from 'react-router-dom';
import { getCurrencyByRegion } from '../../app/utils/util';
import { AppPathEnum } from '../../app/enums/appPathEnum';
import { CountryNameEnum } from '../../app/enums/countryNameEnum';
import { useGetPersonalDetails } from '../../app/customHooks/useGetPersonalDetails';
import { useGetDonationDetails } from '../../app/customHooks/useGetDonationDetails';
import { useReferenceData } from '../../app/customHooks/useReferenceData';

export default function DonationsForm() {

  const { region } = useParams<{ region: string }>();
  const history = useHistory();

  const steps = [DonationsIrelandStepEnum.PersonalDetails, DonationsIrelandStepEnum.PostalAddress,
  DonationsIrelandStepEnum.DonationDetails];

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <PersonalDetails />
      case 1:
        return <PostalAddress />
      case 2:
        return <DonationDetails />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(DonationValidationSchemas[activeStep])
  });

  const { trigger, formState: { isDirty } } = methods;
  const formObj = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');
  const [pageLoading, setPageLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { staticDataStateStatus } = useReferenceData();
  const { personalDetailsStatus } = useGetPersonalDetails();
  const { donationDetailsStatus } = useGetDonationDetails();

  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);

  const MapToPersonalDetails = () => {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      titleId: formObj.Title,
      firstName: formObj.FirstName,
      lastName: formObj.Surname,
      mobile: formObj?.PreferredPhoneNumber
    }
    return data;
  }

  const MapToPostalAddressDetails = () => {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      postalAddress: formObj.PostalAddressLines,
      postalAddressCountryId: formObj.PostalAddressCountry
    }
    return data;
  }

  const MapToDonationDetails = () => {
    let donationBasketObj: CreateDonationBasketCommand = {
      fundId: formObj.Fund,
      amount: formObj.GiftAmount,
      legacy: formObj.WillingToLeaveLegacy !== undefined
        ? formObj.WillingToLeaveLegacy.length > 0 ? true : false
        : false,
      currencyCode: getCurrencyByRegion(region)?.toString()
    };

    return donationBasketObj;
  }

  const savePersonalDetails = (personalDetailsDto: PersonalDetailsDto) => {
    setPageLoading(true);
    dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPersonalDetailsAsync(personId)).then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          toast.success(MESSAGE_DETAILS_SAVED);
          setPageLoading(false);
        });
      }
      else {
        setPageLoading(false);
      }
    });
  }

  const submitDonationDetails = () => {
    setPageLoading(true);
    dispatch(submitToBasketAsync(MapToDonationDetails())).then((basketResponse: any) => {
      if (basketResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPaymentIdFromBasketAsync(basketResponse.payload)).then((paymentIdFromBasketResponse: any) => {
          if (paymentIdFromBasketResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
            let paymentRedirectProps: PaymentRedirectProps = {
              cancelUrl: `${window.location.href}&cancelPayment=true`,
              paymentId: paymentIdFromBasketResponse.payload,
              successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_DONATION_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_DONATIONS_URI)}`
            };
            dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
              window.location.replace(paymentRedirectResponse.payload);
              setPageLoading(false);
            });
          }
          else {
            setPageLoading(false);
          }
        });
      }
      else {
        setPageLoading(false);
      }
    });
  }

  const handleNext = async () => {

    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            savePersonalDetails(MapToPersonalDetails());
            break;
          case 1:
            savePersonalDetails(MapToPostalAddressDetails());
            break;
          case 2:
            submitDonationDetails();
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  }

  const payByDirectDebit = async () => {

    const isStepValid = await trigger();

    if (isStepValid) {
      setPageLoading(true);
      dispatch(submitToBasketAsync(MapToDonationDetails())).then((basketResponse: any) => {
        if (basketResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          setPageLoading(false);
          const queryParams = new URLSearchParams(window.location.search);
          const cancelUrl = queryParams.get("cancelUrl");
          const basketId = queryParams.get("basketId");
          let urlToRedirect = '';

          if ((!cancelUrl || cancelUrl === null) && (!basketId || basketId === null)) {
            urlToRedirect = `${AppPathEnum.DONATIONS_DIRECT_DEBIT}${window.location.search}&cancelUrl=${window.location.pathname}&basketId=${basketResponse.payload}`;
          }
          else if (!cancelUrl || cancelUrl === null) {
            urlToRedirect = `${AppPathEnum.DONATIONS_DIRECT_DEBIT}${window.location.search}&cancelUrl=${window.location.pathname}`;
          }
          else if (!basketId || basketId === null) {
            urlToRedirect = `${AppPathEnum.DONATIONS_DIRECT_DEBIT}${window.location.search}&basketId=${basketResponse.payload}`;
          }
          else {
            urlToRedirect = `${AppPathEnum.DONATIONS_DIRECT_DEBIT}${window.location.search}`;
          }

          history.push(urlToRedirect);
        }
        else {
          setPageLoading(false);
        }
      });
    }
  }

  const _handleBack = async () => {
    setActiveStep(activeStep - 1);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || personalDetailsStatus === ApiRequestStatus.Pending
    || donationDetailsStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <FormProvider {...methods}>
      <MembershipRenewalHeader />

      <br></br>
      <br></br>

      <Button variant="contained" onClick={() => {
        history.push(`${AppPathEnum.DONATION_DASHBOARD}?ref=${String(new URLSearchParams(window.location.search).get("ref"))}`);
      }}>
        {MESSAGE_BACK_TO_DONATIONS}
      </Button>

      <br></br>
      <br></br>
      <br></br>

      <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <br></br>

      {activeStep !== 0 && (
        <Button variant="contained" onClick={_handleBack}>
          {MESSAGE_BACK_TO_PREVIOUS_STEP}
        </Button>
      )}

      {activeStep !== steps.length &&
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>

              {activeStep < steps.length - 1 &&
                <>
                  <Grid item xs={8}></Grid>

                  <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT}
                    </Button>
                  </Grid>
                </>
              }

              {activeStep === steps.length - 1 &&
                <>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Pay by card
                    </Button>
                  </Grid>

                  {region.toLowerCase() === CountryNameEnum.IRELAND.toLowerCase() &&
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" onClick={payByDirectDebit}>
                        Pay by direct debit
                      </Button>
                    </Grid>
                  }
                </>
              }
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>
      }
    </FormProvider >
  );
};
