import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { membershipOnboardingValidationSchema } from './common/membershipOnboardingValidationSchema';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personalDetailsSlice';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import { toast } from 'react-toastify';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_SAVE_AND_CONTINUE, MESSAGE_PROCESSING_DETAILS,
  MESSAGE_NEXT, MESSAGE_SUBMIT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NOT_A_VALUE, UN_AUTHORISED_AS_USER_RATIFIED,
  UN_AUTHORISED_AS_USER_CONFERRED, UN_AUTHORISED_AS_USER_DECLINED
} from '../../app/utils/constant';
import DeclarationAndPetition from './DeclarationAndPetition';
import OnboardingInformation from './OnboardingInformation';
import {
  getFamOnboardingDetailsAsync, updateFamOnboardingDetailsAsync, submitFamOnboardingDetailsAsync, basketAsync
} from '../../app/slices/famOnboardingSlice';
import { FamOnboardingStatus } from '../../app/models/staticData/famOnboardingStatus';
import { MemberTransferStatusCodeEnum } from '../../app/enums/memberTransferStatusCodeEnum';
import { FAMOnboarding } from '../../app/models/membership/famOnboarding';
import MemberPostalAddress from '../../app/models/membership/MemberPostalAddress';
import MemberPersonalDetails from '../../app/models/membership/MemberPersonalDetails';
import { getFAMDetails, updateFAMDetails } from '../../app/slices/famSlice';
import { LocationPreferences } from '../../app/models/membership/famDetails';
import LocationAndPreferencesDetails from '../../app/models/membership/LocationAndPreferencesDetails';
import { getPaymentIdFromBasketAsync, getPaymentRedirectAsync, getPaymentSummaryAsync } from '../../app/slices/paymentSlice';
import MembershipPaymentSummary from '../../app/models/membership/MembershipPaymentSummary';
import { PaymentRedirectProps } from '../../app/models/membership/paymentRedirectProps';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import Unauthorized from '../../app/errors/Unauthorized';
import { SpaName } from '../../app/enums/spaName';
import MembershipOnboardingHeader from './MembershipOnboardingHeader';
import { MembershipOnboardingStepsEnum } from './common/membershipOnboardingStepsEnum';
import { useGetFamOnboardingDetails } from '../../app/customHooks/useGetFamOnboardingDetails';
import { useGetFamDetails } from '../../app/customHooks/useGetFamDetails';
import { useGetPersonalDetails } from '../../app/customHooks/useGetPersonalDetails';
import { useReferenceData } from '../../app/customHooks/useReferenceData';

export default function MembershipOnboardingForm() {

  const steps = [MembershipOnboardingStepsEnum.PersonalDetails, MembershipOnboardingStepsEnum.PostalAddress,
  MembershipOnboardingStepsEnum.Location, MembershipOnboardingStepsEnum.OnboardingInformation,
  MembershipOnboardingStepsEnum.DeclarationAndPetition];

  function getStepContent(step: number) {
    if (famOnboardingDetails && famOnboardingDetails !== null &&
      famOnboardingDetails[0]?.famOnboardingStatusId &&
      famOnboardingDetails[0]?.famOnboardingStatusId !== null) {
      switch (step) {
        case 0:
          return <MemberPersonalDetails />
        case 1:
          return <MemberPostalAddress />
        case 2:
          return <LocationAndPreferencesDetails />
        case 3:
          return <OnboardingInformation famOnboardingDetails={famOnboardingDetails} />
        case 4:
          return <DeclarationAndPetition />
        default:
          throw new Error('Unknown step');
      }
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(membershipOnboardingValidationSchema[activeStep])
  });

  const { trigger, formState: { isDirty } } = methods;
  const formObj = methods.watch();

  const dispatch = useAppDispatch();

  const { countries, famOnboardingStatuses, famMembershipTypes } = useAppSelector(state => state.ReferenceData);
  const { personalDetails, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { basketId, famOnboardingDetails, submitFamOnboardingDetailsStatus,
    updateFamOnboardingDetailsStatus, basketIdStatus } = useAppSelector(state => state.famOnboarding);
  const { famDetails, updateFamDetailsStatus } = useAppSelector(state => state.famDetails);
  const { paymentRedirectStatus, paymentDetails, paymentSummaryStatus, paymentId, paymentIdStatus }
    = useAppSelector(state => state.payment);

  const [pageLoading, setPageLoading] = useState<boolean | undefined>(undefined);
  const [famOnboardingStatus, setFamOnboardingStatus] = useState<FamOnboardingStatus>();

  useEffect(() => {
    if ((famOnboardingStatuses !== null && famOnboardingStatuses?.length > 0)
      && famOnboardingDetails && famOnboardingDetails !== null
      && famOnboardingDetails[0]?.famOnboardingStatusId && famOnboardingDetails[0]?.famOnboardingStatusId !== null) {
      setFamOnboardingStatus(famOnboardingStatuses?.find(x => x.id === famOnboardingDetails[0]?.famOnboardingStatusId));
    }
  }, [famOnboardingDetails, famOnboardingStatuses, famOnboardingStatus]);

  useEffect(() => {
    if ((basketId === null || basketId === '')
      && famOnboardingDetails && famOnboardingDetails !== null
      && famOnboardingDetails[0]?.famOnboardingStatusId && famOnboardingDetails[0]?.famOnboardingStatusId !== null
      && basketIdStatus !== ApiRequestStatus.Pending
      && famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Submitted) {
      famOnboardingDetails[0].famExamId !== null && dispatch(basketAsync(famOnboardingDetails[0].famExamId));
    }
  }, [basketId, famOnboardingDetails, basketIdStatus, famOnboardingStatus, dispatch]);

  const { staticDataStateStatus } = useReferenceData();
  const { personalDetailsStatus } = useGetPersonalDetails();
  const { famOnboardingDetailsStatus } = useGetFamOnboardingDetails();
  const { famDetailsStatus } = useGetFamDetails();

  function MapToPersonalDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      titleId: formObj.Title,
      middleName: personalDetails?.middleName === formObj.MiddleName ? null : formObj.MiddleName,
      prefferedName: formObj.PreferredName,
      genderId: formObj.Gender,
      nationalityId: formObj.CountryOfNationality,
      dateOfBirth: formObj.DateOfBirth,
      mobile: formObj.PreferredPhoneNumber,
      disciplineId: formObj.Discipline,
      areaOfPracticeId: formObj.AreaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE ? '' : formObj.AreaOfPracticeMoreDetails
    }
    return data;
  }

  function MapToPostalAddressDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      postalAddress: formObj.PostalAddressLines,
      postalAddressCountryId: formObj.PostalAddressCountry
    }
    return data;
  }

  function MapToLocationDetails() {
    const data: PersonalDetailsDto = {
      ...personalDetails, id: personId,
      locationCountryId: formObj.LocationCountry,
      locationCountrySubdivisionId: formObj.CountrySubdivision === MESSAGE_NOT_A_VALUE ? '' : formObj.CountrySubdivision
    }
    return data;
  }

  function MapTofamOnboardingDetails() {
    if (famOnboardingDetails && famOnboardingDetails !== null && famOnboardingDetails.length > 0) {
      let famOnboardingDetailsObj: FAMOnboarding = famOnboardingDetails[0];
      const data: FAMOnboarding = {
        famExamId: famOnboardingDetailsObj?.famExamId ? famOnboardingDetailsObj?.famExamId : '',
        famOnboardingStatusId: famOnboardingDetailsObj?.famOnboardingStatusId ? famOnboardingDetailsObj?.famOnboardingStatusId : '',
        famEntryMethodId: famOnboardingDetailsObj?.famEntryMethodId ? famOnboardingDetailsObj?.famEntryMethodId : '',
        famMembershipTypeId: famOnboardingDetailsObj?.famMembershipTypeId ? famOnboardingDetailsObj?.famMembershipTypeId : '',
        membershipAwardId: famOnboardingDetailsObj?.membershipAwardId ? famOnboardingDetailsObj?.membershipAwardId : '',
        ratificationDate: famOnboardingDetailsObj?.ratificationDate ? famOnboardingDetailsObj?.ratificationDate : null,
        declarationDate: famOnboardingDetailsObj?.declarationDate ? famOnboardingDetailsObj?.declarationDate : null,
        electionPetitionDate: famOnboardingDetailsObj?.electionPetitionDate ? famOnboardingDetailsObj?.electionPetitionDate : null,
        personId: personId,
        royalCollegeId: formObj.WhichRoyalCollegeAreYouTransferringFrom,
        nameOnCert: formObj.NameOnCertificate
      }
      return data;
    }

    return {
      famExamId: '', famOnboardingStatusId: '', famEntryMethodId: '', famMembershipTypeId: '',
      membershipAwardId: '', ratificationDate: null, declarationDate: null, electionPetitionDate: null,
      personId: personId, royalCollegeId: formObj.WhichRoyalCollegeAreYouTransferringFrom,
      nameOnCert: formObj.NameOnCertificate
    };
  }

  const MapToFamDetails = () => {
    let famDetailsObj = {
      ...famDetails,
      scopePreferenceId: formObj.SurgeonsScopeMagazine,
      surgeonJournalPreferenceId: formObj.TheSurgeonJournal,
      personId: personId
    };

    return famDetailsObj;
  }

  const savePublicationPreferences = (locationPreferencesDto: LocationPreferences) => {
    dispatch(updateFAMDetails(locationPreferencesDto))
      .then((updateFAMDetailsResponse: any) => {
        if (updateFAMDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          dispatch(updatePersonalDetailsStateAsync(MapToLocationDetails())).then((personalDetailsResponse: any) => {
            if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
              dispatch(getPersonalDetailsAsync(personId)).then((getPersonalDetailsResponse: any) => {
                if (getPersonalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                  toast.success(MESSAGE_DETAILS_SAVED);
                  setActiveStep(prevActiveStep => prevActiveStep + 1);
                }
              });
            }
          });
          dispatch(getFAMDetails(personId));
        }
      });
  }

  const savePersonalDetails = (personalDetailsDto: PersonalDetailsDto) => {
    dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
      if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
        dispatch(getPersonalDetailsAsync(personId)).then(() => {
          toast.success(MESSAGE_DETAILS_SAVED);
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        });
      }
    });
  }

  const savefamOnboardingDetails = () => {
    dispatch(updateFamOnboardingDetailsAsync(MapTofamOnboardingDetails()))
      .then((response: any) => {
        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          dispatch(getFamOnboardingDetailsAsync()).then(() => {
            toast.success(MESSAGE_DETAILS_SAVED);
            setActiveStep(prevActiveStep => prevActiveStep + 1);
          });
        };
      })
  }

  const submitMemberOnboardingDetails = () => {
    if (famOnboardingDetails && famOnboardingDetails !== null && famOnboardingDetails[0].famExamId !== null) {
      dispatch(submitFamOnboardingDetailsAsync(famOnboardingDetails[0].famExamId)).then((submitOnboardingDetailsResponse: any) => {
        if (submitOnboardingDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          toast.success(MESSAGE_DETAILS_SAVED);
          dispatch(getFamOnboardingDetailsAsync());
        };
      });
    }
  }

  const handleNext = async () => {

    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            savePersonalDetails(MapToPersonalDetails());
            break;
          case 1:
            savePersonalDetails(MapToPostalAddressDetails());
            break;
          case 2:
            savePublicationPreferences(MapToFamDetails());
            break;
          case 3:
            savefamOnboardingDetails();
            break;
          case 4:
            submitMemberOnboardingDetails();
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  }

  useEffect(() => {
    if (!paymentDetails && paymentId !== '' && paymentId !== null) {
      paymentId && dispatch(getPaymentSummaryAsync(paymentId));
    }
  }, [paymentDetails, paymentId, dispatch]);

  useEffect(() => {
    if ((basketId === '' || basketId === null) && famOnboardingDetails && famOnboardingDetails !== null
      && famOnboardingDetails.length > 0 && famOnboardingDetails[0]?.famExamId !== null
      && (famOnboardingStatuses !== null && famOnboardingStatuses?.length > 0
        && famOnboardingDetails[0]?.famOnboardingStatusId === famOnboardingStatuses.find(x => x.code === MemberTransferStatusCodeEnum.Submitted)?.id)) {
      dispatch(basketAsync(famOnboardingDetails[0].famExamId));
    }
  }, [basketId, famOnboardingDetails, famOnboardingStatuses, dispatch]);

  useEffect(() => {
    if (basketId !== '' && basketId !== null) {
      dispatch(getPaymentIdFromBasketAsync(basketId));
    }
  }, [basketId, dispatch]);

  useEffect(() => {
    if (famOnboardingStatus && (famOnboardingStatus.code === MemberTransferStatusCodeEnum.Ratified
      || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Conferred
      || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Declined)) {

      switch (famOnboardingStatus.code) {
        case MemberTransferStatusCodeEnum.Ratified:
          toast.error(UN_AUTHORISED_AS_USER_RATIFIED);
          break;
        case MemberTransferStatusCodeEnum.Conferred:
          toast.error(UN_AUTHORISED_AS_USER_CONFERRED);
          break;
        case MemberTransferStatusCodeEnum.Declined:
          toast.error(UN_AUTHORISED_AS_USER_DECLINED);
          break;
        default:
          break;
      }
    }
  }, [famOnboardingStatus]);

  const paymentOnClickHandler = () => {
    if (paymentId && paymentId !== null) {
      setPageLoading(true);
      let paymentRedirectProps: PaymentRedirectProps = {
        cancelUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_MEMBERSHIP_ONBOARDING_URI)}/${window.location.search}&cancelPayment=true`,
        paymentId: paymentId,
        successUrl: `${String(process.env.REACT_APP_REDIRECT_URI)}${String(process.env.REACT_APP_PAYMENT_SUCCESS_URI)}/${window.location.search}&retry-url=${String(process.env.REACT_APP_MEMBERSHIP_ONBOARDING_URI)}`
      };
      dispatch(getPaymentRedirectAsync(paymentRedirectProps)).then((paymentRedirectResponse: any) => {
        window.location.replace(paymentRedirectResponse.payload);
      });
    }
  }

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
    || personalDetailsStatus === ApiRequestStatus.Pending
    || famDetailsStatus === ApiRequestStatus.Pending
    || famOnboardingDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending
    || submitFamOnboardingDetailsStatus === ApiRequestStatus.Pending
    || paymentRedirectStatus === ApiRequestStatus.Pending
    || updateFamOnboardingDetailsStatus === ApiRequestStatus.Pending
    || updateFamDetailsStatus === ApiRequestStatus.Pending
    || paymentSummaryStatus === ApiRequestStatus.Pending
    || paymentIdStatus === ApiRequestStatus.Pending
    || basketIdStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  if (famOnboardingStatus && (famOnboardingStatus.code === MemberTransferStatusCodeEnum.Ratified
    || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Conferred
    || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Declined)) {

    switch (famOnboardingStatus.code) {
      case MemberTransferStatusCodeEnum.Ratified:
        return <Unauthorized message={UN_AUTHORISED_AS_USER_RATIFIED} />
      case MemberTransferStatusCodeEnum.Conferred:
        return <Unauthorized message={UN_AUTHORISED_AS_USER_CONFERRED} />
      case MemberTransferStatusCodeEnum.Declined:
        return <Unauthorized message={UN_AUTHORISED_AS_USER_DECLINED} />
      default:
        break;
    }
  }

  return (
    <FormProvider {...methods}>
      <MembershipOnboardingHeader famOnboardingStatus={famOnboardingStatus} />
      <br></br>{(famOnboardingStatus === undefined || famOnboardingStatus.code === MemberTransferStatusCodeEnum.Pending
        || famOnboardingStatus.code === MemberTransferStatusCodeEnum.RequiredFurtherDetails
        || famOnboardingStatus.code === MemberTransferStatusCodeEnum.FmOnboarding) &&
        <>
          <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <br></br>
          {activeStep !== 0 && (
            <Button variant="contained" onClick={_handleBack}>
              {MESSAGE_BACK_TO_PREVIOUS_STEP}
            </Button>
          )}

          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? MESSAGE_SUBMIT : (isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT)}
                </Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>
      }

      {famOnboardingStatus?.code === MemberTransferStatusCodeEnum.Submitted && famDetails &&
        paymentDetails && countries && famMembershipTypes &&
        <MembershipPaymentSummary basketId={basketId} paymentOnClickHandler={paymentOnClickHandler}
          retryUrl={process.env.REACT_APP_MEMBERSHIP_ONBOARDING_URI} SpaName={SpaName.MEMBER_ONBOARDING}
          membershipType={famOnboardingDetails !== null ? famMembershipTypes.find(x => x.id === famOnboardingDetails[0].famMembershipTypeId)?.value : ''} />}
    </FormProvider >
  );
}
