import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getDonationDetailsAsync } from '../slices/donationSlice';

export const useGetDonationDetails = () => {
    const dispatch = useAppDispatch();
    const { donationDetails, donationDetailsStatus } = useAppSelector(state => state.donations);

    useEffect(() => {
        if (donationDetails === null && donationDetailsStatus === ApiRequestStatus.Idle) {
            dispatch(getDonationDetailsAsync());
        }
    }, [donationDetails, donationDetailsStatus, dispatch]);

    return { donationDetailsStatus };
};
