import { Box, Paper, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
import DonationFormModel from "./common/donationFormModel";
import { MapFundsToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import AppCheckboxField from "../../app/components/AppCheckboxField";
import { useAppSelector } from "../../app/store/configureStore";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCurrencyByRegion } from "../../app/utils/util";
import { DropdownItem } from "../../app/models/common/dropdownItem";

export default function DonationDetails() {
    const methods = useFormContext();
    const { control } = methods;

    const { funds, currencies } = useAppSelector(state => state.ReferenceData);
    const [fundsByCurrency, setFundsByCurrency] = useState<DropdownItem[]>([]);

    const { region } = useParams<{ region: string }>();

    useEffect(() => {
        let currencyId = currencies?.find(x => x.code === getCurrencyByRegion(region).toString())?.id;
        setFundsByCurrency(MapFundsToDropdownItems(funds?.filter(x => x.currencyId === currencyId && x.active === true) ?? []));
    }, []);

    const {
        formField: {
            Fund,
            GiftAmount,
            WillingToLeaveLegacy
        }
    } = DonationFormModel;

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Donation details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={Fund.name} label={Fund.label}
                        items={fundsByCurrency} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={GiftAmount.name} label={GiftAmount.label.replace("##CURRENCY_PLACE_HOLDER##", getCurrencyByRegion(region))} />
                </Grid>

                <Grid item xs={12}>
                    <AppCheckboxField
                        control={control}
                        name={WillingToLeaveLegacy.name}
                        label={WillingToLeaveLegacy.label}
                        options={[{
                            id: WillingToLeaveLegacy.name,
                            value: WillingToLeaveLegacy.label
                        }]} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}