import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateDonationBasketCommand } from "../models/donation/createDonationBasketCommand";
import { Commitment } from "../models/donation/commitment";

interface famState {
    basketId: string | null;
    donationDetails: Commitment[] | null;

    donationDetailsStatus: ApiRequestStatus;
    submitDonationDetailsStatus: ApiRequestStatus;
}

const initialState: famState = {
    basketId: null,
    donationDetails: null,
    
    donationDetailsStatus: ApiRequestStatus.Idle,
    submitDonationDetailsStatus: ApiRequestStatus.Idle
}

export const submitToBasketAsync = createAsyncThunk<string, CreateDonationBasketCommand>(
    'donationSlice/submitToBasketAsync',
    async (donationCommand, thunkAPI) => {
        try {
            return agent.Donation.submitToBasketAsync(donationCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDonationDetailsAsync = createAsyncThunk<Commitment[]>(
    'donationSlice/getDonationDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Donation.getDonationDetailsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const donationSlice = createSlice({
    name: 'donationSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getDonationDetailsAsync.pending, (state) => {
            state.donationDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDonationDetailsAsync.fulfilled, (state, action) => {
            state.donationDetails = action.payload;
            state.donationDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDonationDetailsAsync.rejected, (state) => {
            state.donationDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitToBasketAsync.pending, (state) => {
            state.submitDonationDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitToBasketAsync.fulfilled, (state, action) => {
            state.basketId = action.payload;
            state.submitDonationDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitToBasketAsync.rejected, (state) => {
            state.submitDonationDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
