import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getFilesAsync } from '../../features/myDocuments/documentSlice';

export const useGetFiles = () => {
    const dispatch = useAppDispatch();
    const { files, status } = useAppSelector(state => state.document);

    useEffect(() => {
        if (files === null && status === ApiRequestStatus.Idle) {
            dispatch(getFilesAsync());
        }
    }, [files, status, dispatch]);

    return { status };
};
