import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PersonalDetailsDto } from "../models/person/personalDetailsDto";

interface PersonalDetailsState {
    personalDetails: PersonalDetailsDto | null;
    
    personalDetailsStatus: ApiRequestStatus;
    updatePersonalDetailsStatus: ApiRequestStatus;
}

const initialState: PersonalDetailsState = {
    personalDetails: null,

    personalDetailsStatus: ApiRequestStatus.Idle,
    updatePersonalDetailsStatus: ApiRequestStatus.Idle
}

export const getPersonalDetailsAsync = createAsyncThunk<PersonalDetailsDto, string>(
    'personalDetails/getPersonalDetailsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsRegistration.getPersonalDetails(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updatePersonalDetailsStateAsync = createAsyncThunk<void, PersonalDetailsDto>(
    'personalDetails/updatePersonalDetailsStateAsync',
    async (personalDetails, thunkAPI) => {
        try {
            return agent.PersonalDetails.updatePersonalDetails(personalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const personalDetailsSlice = createSlice({
    name: 'personalDetailsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPersonalDetailsAsync.pending, (state) => {
            state.personalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPersonalDetailsAsync.fulfilled, (state, action) => {
            state.personalDetailsStatus = ApiRequestStatus.Fulfilled;
            state.personalDetails = action.payload;
        });
        builder.addCase(getPersonalDetailsAsync.rejected, (state) => {
            state.personalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updatePersonalDetailsStateAsync.pending, (state) => {
            state.updatePersonalDetailsStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(updatePersonalDetailsStateAsync.fulfilled, (state) => {
            state.updatePersonalDetailsStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(updatePersonalDetailsStateAsync.rejected, (state) => {
            state.updatePersonalDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
