import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { MembershipDirectDebitValidationSchemas } from "../directDebit/directDebitValidationSchemas";
import DirectDebitFormModel from "../directDebit/directDebitFormModel";
import AppTextInput from "../../components/AppTextInput";
import AppDierctDebitDeclarationCheckbox from "../directDebit/AppDierctDebitDeclarationCheckbox";
import { getPersonalDetailsAsync } from "../../slices/personalDetailsSlice";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../enums/userClaims";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import LoadingComponent from "../../layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../utils/constant";
import { submitDirectDebitDetailsAsync } from "../../slices/directDebitMandateSlice";
import { CreateDirectDebitMandateDto } from "../directDebit/createDirectDebitMandateDto";
import { toast } from "react-toastify";
import SuccessResponseMessage from "../../components/SuccessResponseMessage";
import { useGetPersonalDetails } from "../../customHooks/useGetPersonalDetails";
import { ApiRequestStatus } from "../../enums/apiRequestStatus";
import { CreateDirectDebitMandateCommand } from "../directDebit/createDirectDebitMandateCommand";

export default function MembershipDirectDebit() {

    const location = useLocation();

    const basketId = new URLSearchParams(location.search).get('basketId');

    const history = useHistory();

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(MembershipDirectDebitValidationSchemas)
    });

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [pageLoading, setPageLoading] = useState(false);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

    const dispatch = useAppDispatch();

    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const { personalDetails } = useAppSelector(state => state.personalDetail);

    const { personalDetailsStatus } = useGetPersonalDetails();

    const {
        formField: {
            FirstName,
            Surname,
            FullName,
            BIC,
            IBAN,
            Declaration
        }
    } = DirectDebitFormModel;

    const MapToCreateDirectDebitMandateDto = () => {
        const data: CreateDirectDebitMandateCommand = {
            personId: personId,
            firstName: formObj.FirstName,
            surname: formObj.Surname,
            IBAN: formObj.IBAN,
            BIC: formObj.BIC,
            ShoppingBasketId: basketId,
            Recurring: true,
            IntervalCount: 1
        }
        return data;
    }

    const payDirectDebit = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitDirectDebitDetailsAsync(MapToCreateDirectDebitMandateDto())).then((response: any) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        dispatch(getPersonalDetailsAsync(personId)).then(() => {
                            toast.success(MESSAGE_DETAILS_SAVED);
                            setPageLoading(false);
                            setIsPaymentSuccessful(true);
                        });
                    }
                    else {
                        setPageLoading(false);
                    }
                });
            }
        }
    }

    useEffect(() => {
        reset({
            [FirstName.name]: personalDetails?.firstName,
            [Surname.name]: personalDetails?.lastName,
            [Declaration.name]: []
        })
    }, [personalDetails]);

    if (isPaymentSuccessful === true) {
        return <SuccessResponseMessage header={'Thank you'}
            body={`You have successfully configured direct debit mandate.`} />
    }

    if (pageLoading === true || personalDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Direct debit</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1'>
                        Fellows and Members Office<br></br>
                        RCSI (Royal College of Surgeons in Ireland)<br></br>
                        123 St Stephens Green<br></br>
                        Dublin 2, Ireland<br></br>
                        Tel: +353 1 402 2729<br></br>
                        Email: fellows@rcsi.ie<br></br>
                        Creditor Identifier Number: IE21SDD360106
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={FirstName.name} label={FirstName.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={Surname.name} label={Surname.label} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {FullName.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={BIC.name} label={BIC.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={IBAN.name} label={IBAN.label} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        Please note direct debits will be taken annually in advance of
                        the Member/ Fellow subscription year.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppDierctDebitDeclarationCheckbox
                        control={control}
                        name={Declaration.name}
                        option={{
                            id: Declaration.name,
                            value: Declaration.label
                        }} />
                </Grid>

                <Grid item xs={11}>
                    <Typography variant='body1'>
                        <b>Note</b> - As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with
                        your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights
                        are explained in a statement that you can obtain from your bank.
                    </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={() =>
                        history.push(`${String(new URLSearchParams(window.location.search).get("cancelUrl"))}${window.location.search}`)
                    }>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={payDirectDebit}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </FormProvider>
}
