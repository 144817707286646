import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getDirectDebitMandateByIdAsync } from '../slices/directDebitMandateSlice';

export const useGetDirectDebitMandateById = (directDebitId: string) => {
    const dispatch = useAppDispatch();
    const { directDebitMandate, directDebitMandateByIdStatus } = useAppSelector(state => state.directDebit);

    useEffect(() => {
        if (directDebitId !== "" && directDebitMandate === null && 
            directDebitMandateByIdStatus === ApiRequestStatus.Idle) {
            dispatch(getDirectDebitMandateByIdAsync(directDebitId));
        }
    }, [directDebitId, directDebitMandate, directDebitMandateByIdStatus, dispatch]);

    return { directDebitMandateByIdStatus };
};
