import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import agent from "../api/agent";
import { FAMOnboarding } from "../models/membership/famOnboarding";

interface MembershipOnBoardingState {
    famOnboardingDetails: FAMOnboarding[] | null;
    famOnboardingDetailsById: FAMOnboarding[] | null;
    basketId: string;
    famOnboardingCanPayStatusCodes: string[] | null;

    famOnboardingDetailsStatus: ApiRequestStatus;
    getFamOnboardingDetailsByIdStatus: ApiRequestStatus;
    updateFamOnboardingDetailsStatus: ApiRequestStatus;
    submitFamOnboardingDetailsStatus: ApiRequestStatus;
    basketIdStatus: ApiRequestStatus;
    famOnboardingCanPayStatusCodesStatus: ApiRequestStatus;
}

const initialState: MembershipOnBoardingState = {
    famOnboardingDetails: null,
    famOnboardingDetailsById: null,
    basketId: '',
    famOnboardingCanPayStatusCodes: null,

    famOnboardingDetailsStatus: ApiRequestStatus.Idle,
    getFamOnboardingDetailsByIdStatus: ApiRequestStatus.Idle,
    updateFamOnboardingDetailsStatus: ApiRequestStatus.Idle,
    submitFamOnboardingDetailsStatus: ApiRequestStatus.Idle,
    basketIdStatus: ApiRequestStatus.Idle,
    famOnboardingCanPayStatusCodesStatus: ApiRequestStatus.Idle
}

export const getFamOnboardingDetailsAsync = createAsyncThunk<FAMOnboarding[]>(
    'memberOnboarding/getFamOnboardingDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.MemberOnboarding.getFamOnboardingDetailsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFamOnboardingCanPayStatusCodesAsync = createAsyncThunk<string[]>(
    'memberOnboarding/getFamOnboardingCanPayStatusCodesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.MemberOnboarding.getFamOnboardingCanPayStatusCodesAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFamOnboardingDetailsByIdAsync = createAsyncThunk<FAMOnboarding[], string>(
    'memberOnboarding/getFamOnboardingDetailsByIdAsync',
    async (id, thunkAPI) => {
        try {
            return agent.MemberOnboarding.getByIdAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateFamOnboardingDetailsAsync = createAsyncThunk<boolean, FAMOnboarding>(
    'memberOnboarding/updateFamOnboardingDetailsAsync',
    async (famOnboarding, thunkAPI) => {
        try {
            return agent.MemberOnboarding.updateFamOnboardingDetailsAsync(famOnboarding);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitFamOnboardingDetailsAsync = createAsyncThunk<boolean, string>(
    'memberOnboarding/submitFamOnboardingDetailsAsync',
    async (famExamId, thunkAPI) => {
        try {
            return agent.MemberOnboarding.submitFamOnboardingDetailsAsync(famExamId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const basketAsync = createAsyncThunk<string, string>(
    'memberOnboarding/basketAsync',
    async (famExamId, thunkAPI) => {
        try {
            return agent.MemberOnboarding.basketAsync(famExamId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const famOnboardingSlice = createSlice({
    name: 'famOnboardingSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getFamOnboardingDetailsAsync.pending, (state) => {
            state.famOnboardingDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamOnboardingDetailsAsync.fulfilled, (state, action) => {
            state.famOnboardingDetailsStatus = ApiRequestStatus.Fulfilled;
            state.famOnboardingDetails = action.payload;
        });
        builder.addCase(getFamOnboardingDetailsAsync.rejected, (state) => {
            state.famOnboardingDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFamOnboardingCanPayStatusCodesAsync.pending, (state) => {
            state.famOnboardingCanPayStatusCodesStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamOnboardingCanPayStatusCodesAsync.fulfilled, (state, action) => {
            state.famOnboardingCanPayStatusCodesStatus = ApiRequestStatus.Fulfilled;
            state.famOnboardingCanPayStatusCodes = action.payload;
        });
        builder.addCase(getFamOnboardingCanPayStatusCodesAsync.rejected, (state) => {
            state.famOnboardingCanPayStatusCodesStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFamOnboardingDetailsByIdAsync.pending, (state) => {
            state.getFamOnboardingDetailsByIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getFamOnboardingDetailsByIdAsync.fulfilled, (state, action) => {
            state.getFamOnboardingDetailsByIdStatus = ApiRequestStatus.Fulfilled;
            state.famOnboardingDetailsById = action.payload;
        });
        builder.addCase(getFamOnboardingDetailsByIdAsync.rejected, (state) => {
            state.getFamOnboardingDetailsByIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateFamOnboardingDetailsAsync.pending, (state) => {
            state.updateFamOnboardingDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateFamOnboardingDetailsAsync.fulfilled, (state) => {
            state.updateFamOnboardingDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateFamOnboardingDetailsAsync.rejected, (state) => {
            state.updateFamOnboardingDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitFamOnboardingDetailsAsync.pending, (state) => {
            state.submitFamOnboardingDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitFamOnboardingDetailsAsync.fulfilled, (state) => {
            state.submitFamOnboardingDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitFamOnboardingDetailsAsync.rejected, (state) => {
            state.submitFamOnboardingDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(basketAsync.pending, (state) => {
            state.basketIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(basketAsync.fulfilled, (state, action) => {
            state.basketId = action.payload;
            state.basketIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(basketAsync.rejected, (state) => {
            state.basketIdStatus = ApiRequestStatus.Rejected;
        });
    })
})
