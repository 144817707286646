import { Box, Paper, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
import { MapTitlesToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import DonationFormModel from "./common/donationFormModel";
import { useAppSelector } from "../../app/store/configureStore";


export default function PersonalDetails() {
    const methods = useFormContext();
    const { control, reset } = methods;

    const { titles } = useAppSelector(state => state.ReferenceData);
    const { personalDetails } = useAppSelector(state => state.personalDetail);

    const {
        formField: {
            Title,
            FirstName,
            Surname,
            PreferredPhoneNumber
        }
    } = DonationFormModel;

    useEffect(() => {
        if (personalDetails !== undefined && personalDetails !== null
            && titles && titles?.length > 0) {

            reset({
                [Title.name]: personalDetails.titleId === null
                    ? '' : personalDetails.titleId,
                [FirstName.name]: personalDetails.firstName,
                [Surname.name]: personalDetails.lastName,
                [PreferredPhoneNumber.name]: personalDetails.mobile
            })
        }
    }, [personalDetails, titles, reset]);

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Personal details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={Title.name} label={Title.label}
                        items={MapTitlesToDropdownItems(titles ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={FirstName.name} label={FirstName.label} disabled={true} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={Surname.name} label={Surname.label} disabled={true} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput name={PreferredPhoneNumber.name} label={PreferredPhoneNumber.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}