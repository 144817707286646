import { Box, Button, Grid, Typography } from "@mui/material";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { useAppSelector } from "../../../app/store/configureStore";
import { DirectDebitMandate } from "../../../app/models/directDebit/directDebitMandate";
import React from "react";
import { useHistory } from "react-router-dom";
import { ProductEnum } from "../../../app/enums/productEnum";
import { getCurrencyIconByCode } from "../../../app/utils/util";
import { PaymentFrequencyEnum } from "../../../app/enums/paymentFrequencyEnum";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import { useGetDirectDebitMandates } from "../../../app/customHooks/useGetDirectDebitMandates";

export function MyDirectDebits() {
    const history = useHistory();

    const { directDebitMandates } = useAppSelector(state => state.directDebit);
    const { products, subscriptionIntervalUnits, currencies } = useAppSelector(state => state.ReferenceData);

    const { staticDataStateStatus } = useReferenceData();
    const { getDirectDebitMandatesStatus } = useGetDirectDebitMandates();

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || getDirectDebitMandatesStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Manage Direct Debits</Typography>
                    </Grid>

                    {directDebitMandates && directDebitMandates?.length > 0 &&
                        <>
                            <Grid item xs={2.5} className="wordWrap">
                                <b>Direct Debit Name</b>
                            </Grid>

                            <Grid item xs={4} className="wordWrap">
                                <b>Unique Mandate Reference</b>
                            </Grid>

                            <Grid item xs={2} className="wordWrap">
                                <b>Recurrance period</b>
                            </Grid>

                            <Grid item xs={2} className="wordWrap">
                                <b>Amount</b>
                            </Grid>

                            <Grid item xs={1.5} className="wordWrap">

                            </Grid>

                            {directDebitMandates?.map((item: DirectDebitMandate) => (
                                <React.Fragment key={item.id}>
                                    <Grid item xs={2.5} className="wordWrap">
                                        {products?.find(x => x.id === item.basketProducts[0].productId)?.name}
                                    </Grid>

                                    <Grid item xs={4} className="wordWrap">
                                        {item.reference}
                                    </Grid>

                                    <Grid item xs={2} className="wordWrap">
                                        {(item.intervalCount !== null && item.intervalCount === 3) ?
                                            PaymentFrequencyEnum.Quarterly : products?.find(x => x.id === item.basketProducts[0].productId)?.code ===
                                                ProductEnum.FamAnnualMembership ? PaymentFrequencyEnum.Annual :
                                                `${subscriptionIntervalUnits?.find(x => x.id === item.intervalUnitId)?.code.toUpperCase() ===
                                                    PaymentFrequencyEnum.Year.toUpperCase() ? PaymentFrequencyEnum.Annual : PaymentFrequencyEnum.Monthly}`}
                                    </Grid>

                                    <Grid item xs={2} className="wordWrap">
                                        {products?.find(x => x.id === item.basketProducts[0].productId)?.code ===
                                            ProductEnum.FamAnnualMembership ? 'Membership Rate' :
                                            `${getCurrencyIconByCode(currencies?.find(x => x.id ===
                                                item.basketProducts[0].currencyId)?.code)}
                                             ${item.basketProducts[0].cost}`}
                                    </Grid>

                                    <Grid item xs={1.5} className="wordWrap">
                                        <Button variant="outlined" color="primary" onClick={() => {
                                            history.push(`/manage-direct-debit/${item.id}/${window.location.search}`);
                                        }}>
                                            Manage
                                        </Button>
                                    </Grid>

                                </React.Fragment>
                            ))}
                        </>}

                    {directDebitMandates?.length === 0 &&
                        <Grid item xs={12} className="wordWrap">
                            No direct debits found.
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    );
}
