import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getDirectDebitMandatesAsync } from '../slices/directDebitMandateSlice';

export const useGetDirectDebitMandates = () => {
    const dispatch = useAppDispatch();
    const { directDebitMandates, getDirectDebitMandatesStatus } = useAppSelector(state => state.directDebit);

    useEffect(() => {
        if (directDebitMandates === null && getDirectDebitMandatesStatus === ApiRequestStatus.Idle) {
            dispatch(getDirectDebitMandatesAsync());
        }
    }, [directDebitMandates, getDirectDebitMandatesStatus, dispatch]);

    return { getDirectDebitMandatesStatus };
};
